import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import posterData from "../data/poster-data"

const FilmTV = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "posters" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 471, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            name
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  return (
    <Layout>
      <SEO title="Film/TV" />
      <div className="container">
        <div className="row">
          <div className="col s12">
            <h1>Film/TV</h1>
          </div>
        </div>
        {posterData.map((movie, i) => (
          <div key={i} className="row">
            <div className="col s12 m7 center-align">
              {data.allFile.edges.map((image, i) =>
                image.node.name === movie.img ? (
                  <Img
                    alt={movie.title}
                    fluid={image.node.childImageSharp.fluid}
                    className="projects-img"
                    key={i}
                    loading={movie.loading || "lazy"}
                  />
                ) : null
              )}
            </div>
            <div className="col s12 m5 project-text">
              {movie.subHead ? (
                <>
                  <h2 className="mt-0 mb-0">{movie.title}</h2>
                  <h3 className="mt-0">{movie.subHead}</h3>
                </>
              ) : (
                <h2 className="mt-0">{movie.title}</h2>
              )}
              {movie.cast.map((cast, i) => (
                <>
                  <h4 key={i}>{cast}</h4>
                </>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default FilmTV
