const posterData = [
  // Top 3 have eager loading
  {
    title: "WORTH",
    subHead: "CURRENTLY IN POST PRODUCTION",
    cast: [
      "Michael Keaton",
      "Stanley Tucci",
      "Amy Ryan",
      "Tate Donavan",
      "Director - Sara Colangelo",
    ],
    img: "17_worth",
    loading: "eager"
  },
  {
    title: "PRIMAL",
    cast: [
      "Nicolas Cage",
      "Famke Janssen",
      "Kevin Durand",
      "Director - Nick Powell",
    ],
    img: "16_primal",
    loading: "eager"
  },
  {
    title: "ZOMBIE TIDAL WAVE",
    cast: ["Ian Ziering", "Director - Anthony C. Ferrante"],
    img: "15_zombie-tidal-wave",
    loading: "eager"
  },
  {
    title: "WALKAWAY JOE",
    cast: ["Jeffrey Dean Morgan", "David Strathairn", "Director - Tom Wright"],
    img: "14_walkaway-joe",
  },
  {
    title: "THE VAULT",
    cast: [
      "James Franco",
      "Scott Haze",
      "Taryn Manning",
      "Francesca Eastwood",
      "Director - Dan Bush",
    ],
    img: "13_vault",
  },
  {
    title: "LAP DANCE",
    cast: [
      "James Remar",
      "Carmen Electra",
      "Omari Hardwick",
      "Briana Evigan",
      "Director - Greg Carter",
    ],
    img: "12_lap-dance",
  },
  {
    title: "MY MAN IS A LOSER",
    cast: [
      "John Stamos",
      "Sean Young",
      "Michael Rappaport",
      "Tika Sumpter",
      "Bryan Callen",
      "Director - Mike Young",
    ],
    img: "11_my-man-is-a-loser",
  },
  {
    title: "SAF3 TV SERIES",
    cast: ["Dolph Lundgren"],
    img: "10_saf3",
  },
  {
    title: "KID CANNABIS",
    cast: [
      "Jonathan Daniel Brown",
      "Kenny Wormold",
      "Ron Pealman",
      "John C. McGinley",
      "Director - John Stockwell",
    ],
    img: "09_kid-cannabis",
  },
  {
    title: "PAWN",
    cast: [
      "Forest Whitaker",
      "Michael Chiklis",
      "Common",
      "Ray Liotta",
      "Nikki Reed",
      "Jessica Szohr",
      "Stehen Lang",
      "Jonathan Bennett",
      "Director - David Armstrong",
    ],
    img: "08_pawn",
  },
  {
    title: "SLIGHTLY SINGLE IN LA",
    cast: [
      "Lacey Chabert",
      "Haylie Duff",
      "Jonathan Bennett",
      "Jenna Dewan",
      "Mercea Monroe",
      "Director - Christie Will",
    ],
    img: "07_slightly-single-in-la",
  },
  {
    title: "SOULJA BOY THE MOVIE",
    cast: ["DeAndre Cortez Way (Soulja Boy)"],
    img: "06_soulja-boy",
  },
  {
    title: "TWILIGHT SAGA ECLIPSE",
    cast: [
      "Robert Pattinson",
      "Kristen Stewart",
      "Taylor Lautner",
      "Ashley Green",
      "Nikki Reed",
      "Director - David Slade",
    ],
    img: "05_twilight-eclipse",
  },
  {
    title: "THE INDUSTRY TV SERIES",
    cast: ["Josh and JB Welch"],
    img: "04_industry",
  },
  {
    title: "THE STEPFATHER",
    cast: [
      "Amber Heard",
      "Penn Badgley",
      "Dylan Walsh",
      "Sela Ward",
      "Director - Nelson McCormic",
    ],
    img: "03_stepfather",
  },
  {
    title: "TWILIGHT SAGA NEW MOON",
    cast: [
      "Robert Pattinson",
      "Kristen Stewart",
      "Taylor Lautner",
      "Ashley Green",
      "Nikki Reed",
      "Director - Chris Weitz",
    ],
    img: "02_twilight-new-moon",
  },
  {
    title: "TWILIGHT SAGA",
    cast: [
      "Robert Pattinson",
      "Kristen Stewart",
      "Taylor Lautner",
      "Ashley Green",
      "Nikki Reed",
      "Director - Catherine Hardwicke",
    ],
    img: "01_twilight",
  },
]

export default posterData
